<template>
  <div class="home" v-if="pageData">
<!--    <div class="home-img">-->
<!--      <img src="../../assets/image/banner.png" alt="">-->
<!--      <div class="img-size">-->
<!--        <div class="img-title">心怀大众，善行天下</div>-->
<!--        <div class="img-line-text">以科技助发展、以政策为导向，帮困化急的经营理念，心怀大众、善行天下</div>-->
<!--        <div class="img-line-text">全心全意为京津冀人民服务</div>-->
<!--        <div class="img-more">MORE</div>-->
<!--      </div>-->
<!--    </div>-->
    <el-carousel :interval="5000" class="hidden-sm-and-down" height="750px">
      <el-carousel-item v-for="(item,index) in pageData.ads" :key="index">
        <img v-if="item.materialType == 1" style="width: 100%;height: 100%;" :src=" serverUrl + item.savePath" alt="">
        <video v-else autoplay="autoplay" loop="loop" muted="muted" style="width: 100%;height: 100%;" :src=" serverUrl + item.savePath"></video>
      </el-carousel-item>
    </el-carousel>
    <el-carousel :interval="5000" class="hidden-sm-and-up" height="250px">
      <el-carousel-item v-for="(item,index) in pageData.ads" :key="index">
        <img v-if="item.materialType == 1" style="width: 100%;height: 100%;" :src=" serverUrl + item.savePath" alt="">
        <video v-else autoplay="autoplay" loop="loop" muted="muted" style="width: 100%;height: 100%;" :src=" serverUrl + item.savePath"></video>
      </el-carousel-item>
    </el-carousel>
    <el-row class="about-us">
      <el-col :xs="24" :sm="12" class="about-us-img">
        <div class="about-img">
          <img :src="serverUrl + pageData.comIntro.coverImage" alt="">
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" class="about-word">
        <div style="font-size: 46px;color:#0D9484;font-weight: bold">ABOUT US</div>
        <div style="font-size: 20px; color:#222222;margin: 15px 0;">关于我们</div>
        <div style="font-size: 16px;line-height: 2em;">{{pageData.comIntro.title}}</div>
        <div style="font-size: 16px;line-height: 2em;">{{pageData.comIntro.description}}</div>
        <div class="img-more" style="margin-top: 15px" @click="toPage('/about')">MORE</div>
      </el-col>
    </el-row>
    <div class="case">
      <img src="../../assets/image/case.png" alt="">
      <div class="case-example">
        <div class="title">工程案例</div>
        <div class="content">Engineering centre</div>
        <div class="content">科艺发展工程案例介绍</div>
      </div>
      <div class="hidden-sm-and-down">
        <swiper class="case-content" ref="caseSwiper" :options="{
                                                                  slidesPerView: 4,
                                                                  loop: true,
                                                                  autoplay: false,
                                                                  allowTouchMove: true,
                                                                  observer: true,
                                                                  observeParents: true,
                                                                  spaceBetween: 0,
                                                                }">
          <swiper-slide v-for="(item, index) in pageData.projs" :key="index">
            <div class="case-img" @click="toMore(item)">
              <img :src="serverUrl + item.coverImage" alt="">
              <div class="case-index">{{ item.title }}</div>
            </div>
          </swiper-slide>
        </swiper>

        <div class="case-btn">
          <el-button @click='swiperNext("caseSwiper")'><i class="el-icon-arrow-left"></i></el-button>
          <el-button @click='swiperPrev("caseSwiper")'><i class="el-icon-arrow-right"></i></el-button>
        </div>
      </div>
      <div class="hidden-sm-and-up">
        <swiper class="case-content" ref="caseSwiper2" :options="{
                                                                  slidesPerView: 2,
                                                                  loop: true,
                                                                  autoplay: false,
                                                                  allowTouchMove: true,
                                                                  observer: true,
                                                                  observeParents: true,
                                                                  spaceBetween: 0,
                                                                }">
          <swiper-slide v-for="(item, index) in pageData.projs" :key="index">
            <div class="case-img" @click="toMore(item)">
              <img :src="serverUrl + item.coverImage" alt="">
              <div class="case-index">{{ item.title }}</div>
            </div>
          </swiper-slide>
        </swiper>

        <div class="case-btn">
          <el-button @click='swiperNext("caseSwiper2")'><i class="el-icon-arrow-left"></i></el-button>
          <el-button @click='swiperPrev("caseSwiper2")'><i class="el-icon-arrow-right"></i></el-button>
        </div>
      </div>
    </div>

    <div class="enterprise">
      <div class="purpler">企业资质</div>
      <div class="mini">enterprise qualification</div>
      <div class="mini">在2020年8月1日荣获建筑业企业资质证书、在2021年7月1日获得企业营业执照</div>
      <el-row class="enterprise-img">
        <el-col :xs="24" :sm="12" v-for="(item,index) in pageData.level" :key="index" >
          <div class="img-wrap" @click="toMore(item)">
            <img :src="serverUrl + item.coverImage" style="display: block;height: 240px;width: 100%;" alt="">
          </div>
          <p>{{ item.title }}</p>
        </el-col>
      </el-row>
    </div>
<!--    <div class="new-energy">-->
<!--      <img src="../../assets/image/newEnergy.png" alt="">-->
<!--      <div class="new-word">-->
<!--        <div class="new-font">国内领先的新能源企业</div>-->
<!--        <div>以科技助发展、以政策为导向，帮困化急的经营理念，心怀大众、善行天下，全心全意为京津冀人民服务。</div>-->
<!--        <div class="img-more">MORE</div>-->
<!--      </div>-->
<!--    </div>-->

    <el-carousel :interval="5000" class="hidden-sm-and-down" height="500px">
      <el-carousel-item v-for="(item,index) in pageData.mAds" :key="index">
        <img v-if="item.materialType == 1" style="width: 100%;height: 100%;" :src=" serverUrl + item.savePath" alt="">
        <video v-else autoplay="autoplay" loop="loop" muted="muted" style="width: 100%;height: 100%;" :src=" serverUrl + item.savePath"></video>
      </el-carousel-item>
    </el-carousel>

    <div class="news-information">
      <div class="purpler">新闻动态</div>
      <div class="mini">new denelopments</div>
      <div class="mini">了解行业知识，掌握行业动态</div>
      <el-row class="news-img">
        <el-col :xs="24" :sm="12" class="left">
          <el-carousel height="324px">
            <el-carousel-item  v-for="(item,index) in pageData.news" :key="index" >
              <img :src="serverUrl + item.coverImage" @click="toMore(item)">
            </el-carousel-item>
          </el-carousel>

        </el-col>
        <el-col :xs="24" :sm="12" class="right">
          <div v-for="(item,index) in pageData.news" :key="index" @click="toMore(item)">
            <div class="right-1">
              <div class="text1">{{ item.createTime.slice(item.createTime.length - 11, item.createTime.length - 9) }}</div>
              <div class="text2">{{ item.createTime.slice(0, item.createTime.length - 12) }}</div>
            </div>
            <div class="right-2">
              <div class="text1">{{ item.title }}</div>
              <div class="text2">{{ item.description }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>


    <div class="links">
      <div class="purpler">友情链接</div>
      <div class="mini">fruendship link</div>
      <div class="mini">合作伙伴的信息展示</div>
      <div class="hidden-sm-and-down">
        <div class="links-swiper">
          <el-button @click='swiperNext("linksSwiper")'>
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <swiper class="links-img" ref="linksSwiper" :options="{
                                                                  slidesPerView: 5,
                                                                  loop: true,
                                                                  autoplay: false,
                                                                  allowTouchMove: true,
                                                                  observer: true,
                                                                  observeParents: true,
                                                                  spaceBetween: 0,
                                                                }">
            <swiper-slide v-for="(item, index) in pageData.fds" :key="index">
              <a :href="item.link" target="_blank">
                <div class="links-img">
                  <img :src="serverUrl + item.logo" alt="">
                  <div>{{ item.name }}</div>
                </div>
              </a>
            </swiper-slide>
          </swiper>
          <el-button class="right-btn" @click='swiperPrev("linksSwiper")'>
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </div>
      <div class="hidden-sm-and-up">
        <div class="links-swiper">
          <el-button @click='swiperNext("linksSwiper2")'>
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <swiper class="links-img" ref="linksSwiper2" :options="{
                                                                  slidesPerView: 2,
                                                                  loop: true,
                                                                  autoplay: false,
                                                                  allowTouchMove: true,
                                                                  observer: true,
                                                                  observeParents: true,
                                                                  spaceBetween: 0,
                                                                }">
            <swiper-slide v-for="(item, index) in pageData.fds" :key="index">
              <a :href="item.link" target="_blank">
                <div class="links-img">
                  <img :src="serverUrl + item.logo" alt="">
                  <div>{{ item.name }}</div>
                </div>
              </a>
            </swiper-slide>
          </swiper>
          <el-button class="right-btn" @click='swiperPrev("linksSwiper2")'>
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getCats} from "../../api/api"
import {serverUrl} from "@/utils/config";

export default {
  name: "Index",
  data() {
    return {
      serverUrl: serverUrl,
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    toMore(data) {
      this.$router.push({
        path: '/new_detail', query: {
          id: data.id,
          backPage: 'home',
          backTitle: '首页',
        }
      });
    },
    toPage(url) {
      this.$router.push({
        path: url, query: {  }
      });
    },
    getPageData() {
      getCats().then((resp) => {
        let {code, data} = resp;
        if (code === 0) {
          this.pageData = data;
        }
      })
    },
    swiperNext(ref) {
      this.$refs[ref].$swiper.slideNext()
    },
    swiperPrev(ref) {
      this.$refs[ref].$swiper.slidePrev()
    }
  },
}
</script>

<style>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
</style>
<style lang="scss" scoped>

@import "./index.scss";

</style>
